.record-table {
  margin-top: 20px;
  width: 100%;
  font-size: 14px;
  color: #333;

  .el-button--text {
    color: #FFAB57;
  }

  .el-button--default {
    height: 24px;
    line-height: 24px;
    padding: 0 15px;
  }

  .finished {
    border-color: #09BEE9;
    color: #09BEE9;
    background: rgba(9, 190, 233, 0.1);
  }

  .suspended {
    border-color: #BBBBBB;
    color: #BBBBBB;
    background: rgba(187, 187, 187, 0.1);
  }

  .experimentCo {
    border-color: #4F9AFE;
    color: #4F9AFE;
    background: rgba(79, 154, 254, 0.1);
  }

  .approval {
    border-color: #FF7B57;
    color: #FF7B57;
    background: rgba(255, 123, 87, 0.1);
  }

  .el-switch.is-disabled ::v-deep {
    opacity: 1;

    .el-switch__core {
      cursor: pointer;
    }
  }

  ::v-deep .colorStyle {
    color: #4F9AFE;
  }

  .wxPay {
    color: #FFAB57;
  }
}


.tableMember ::v-deep {

  .formAdd {
    background: rgba(213, 234, 255, 0.2);

    .el-input__inner {
      border: none;
      background: transparent;
      padding-left: 0;
    }
  }

  .el-input__inner {
    padding-left: 5px;
  }

  .addColor .el-button--text {
    color: #4F9AFE;
  }

  .editColor .el-button--text {
    color: #FFAB57;
  }

}

.btn {
  margin: 15px 0;

  .el-button {
    border-color: #4F9AFE;
    background-color: #4F9AFE;
  }
}

.upload-demo ::v-deep {
  .el-button {
    background: rgba(79, 154, 254, 0.1);
    border: 1px solid #4F9AFE;
    color: #4F9AFE;
  }

  .el-upload-list {
    color: #333333;

    .el-upload-list__item {
      width: 400px;
      height: 32px;
      background: rgba(187, 187, 187, 0.1);
      border: 1px solid #BBBBBB;
      margin-top: 16px;
      line-height: 32px;
      //padding: 0 17px;
      margin-right: 16px;
      position: relative;
      display: inline-block;
    }

    .el-icon-close-tip {
      display: none !important;
    }

    .el-icon-close {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 20px;
      cursor: pointer;
    }
  }
}

.geneTypes {
  ::v-deep .el-form-item__content {
    display: flex;
    flex-flow: wrap;
  }

  .modeFor {
    margin-right: 16px;
    margin-bottom: 16px;

    .smallWidth {
      margin-right: 16px;
    }
  }

  .delIns {
    border-color: #BBBBBB;
    padding: 9px 10px;

    &:hover {
      border-color: #BBBBBB;
    }
  }

  .addInstru {
    border: 1px solid #BBBBBB;
    text-align: left;
    color: #999999;
    height: 32px;

    &:hover, &:focus {
      background-color: transparent;
      color: #999999;
    }
  }

  .geneTypeSubmit {
    background: #4F9AFE;
    height: 32px;
  }
}


.reportList {
  flex-wrap: wrap;

  .report {
    width: 340px;
    height: 32px;
    background: rgba(187, 187, 187, 0.1);
    border: 1px solid #BBBBBB;
    margin-top: 16px;
    line-height: 32px;
    margin-right: 16px;
    padding-left: 10px;
    justify-content: space-between;

    .name {
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .el-icon-close {
    font-size: 20px;
    cursor: pointer;
  }
}
